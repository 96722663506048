<template>
	<div>
		<el-drawer
			title="我是标题"
			:visible.sync="drawer"
			:direction="direction"
			:before-close="handleClose"
			:with-header="false"
		>
			<div class="titlebox">
				<span>日程详情</span>
				<i @click="handleClose" class="el-icon-close" style="color: #000000;font-size: 20px;"></i>
			</div>
			<div class="formbox">
				<el-form
					:model="ruleForm"
					:rules="rules"
					ref="ruleForm"
					label-width="0"
					class="demo-ruleForm"
				>
					<el-form-item label="" prop="startTime">
						<div class="inputtitle">
							<span>*</span>
							开始时间
						</div>
						<div class="choosetime">
							<el-date-picker
								value-format="yyyy-MM-dd HH:mm"
								v-model="ruleForm.startTime"
								type="datetime"
								disabled
								placeholder="选择日期"
							></el-date-picker>
						</div>
					</el-form-item>

					<el-form-item label="" prop="endTime">
						<div class="inputtitle" style="margin-top: 30px;">
							<span>*</span>
							截止时间
						</div>
						<div class="choosetime">
						
							<el-date-picker
							disabled
								value-format="yyyy-MM-dd HH:mm"
								v-model="ruleForm.endTime"
								type="datetime"
								placeholder="选择日期"
							></el-date-picker>
						</div>
					</el-form-item>

					<el-form-item label="" prop="followType">
						<div class="inputtitle" style="margin-top: 25px;">
							<span>*</span>
							跟进方式
						</div>
						<div class="allway">
							<div
								class="oneway"
								v-for="(item, index) in genjinType"
								@click="chooseWay(item, index)"
								:key="index"
							>
								<div :class="{ 'activeway': item.settingId === ruleForm.followType }">
									<p v-if="item.settingId === ruleForm.followType"></p>
								</div>
								<span>{{ item.settingName }}</span>
							</div>
						</div>
					</el-form-item>

					<el-form-item label="" prop="status">
						<div class="inputtitle" style="margin-top: 25px;">
							<span>*</span>
							日程状态
						</div>
						<div class="selectbox1">
							<el-select disabled v-model="ruleForm.status" placeholder="请选择">
								<el-option
									v-for="item in scheduleType"
									:key="item.value"
									:label="item.label"
									:value="item.value"
								></el-option>
							</el-select>
						</div>
					</el-form-item>

					<el-form-item label="" prop="executeUserId">
						<div class="inputtitle" style="margin-top: 25px;">
							<span>*</span>
							执行人员
						</div>
						 <el-select disabled v-model="ruleForm.executeUserId" multiple placeholder="请选择">
						    <el-option
						      v-for="item in employeesList"
						      :key="item.userId"
						      :label="item.realName"
						      :value="item.userId">
						    </el-option>
						  </el-select>
					</el-form-item>

					<el-form-item label="" prop="scheduleContent">
						<div class="inputtitle" style="margin-top: 25px;">
							<span>*</span>
							日程内容
						</div>
						<div class="textareabox">
							<el-input
							disabled
								type="textarea"
								v-model="ruleForm.scheduleContent"
								placeholder="请输入日程内容"
							></el-input>
						</div>
					</el-form-item>
				</el-form>
			
			</div>
		</el-drawer>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { addSchedule,allUserList,editSchedule } from '@/network/api.js';
export default {
	data() {
		return {
			scheduleType: [
				{
					value: 0,
					label: '未完成'
				},
				{
					value: 1,
					label: '已完成'
				}
			],
			ruleForm: {
				endTime: '',
				executeUserId: [],
				followType: '',
				scheduleContent: '',
				scheduleId: '',
				scheduleTitle: '',
				startTime: '',
				status: ''
			},
			rules: {
				scheduleContent: [{ required: true, message: '请输入日程内容', trigger: 'blur' }],
				region: [{ required: true, message: '请选择活动区域', trigger: 'change' }],
				endTime: [{ required: true, message: '请选择截止时间', trigger: 'blur' }],
				startTime: [{ required: true, message: '请选择开始时间', trigger: 'blur' }],
				status: [{  required: true, message: '请选择日程状态', trigger: 'blur' }],
				followType: [{  required: true, message: '请选择跟进方式', trigger: 'change' }],
				executeUserId: [{ type: 'array', required: true, message: '请选择执行人员', trigger: 'change' }],
			},
			drawer: false,
			direction: 'rtl',
			value1: '',
			wayIndex: '',
			employeesList: [],  // 员工列表
		};
	},
	computed: {
		// ...mapState(['genjinType'])
		...mapState('systemModule',{
			genjinType: 'genjinType'
		})
	},
	created() {
		this._allUserList()
	},
	methods: {
		// 编辑日程
		async _editSchedule() {
			await editSchedule(this.ruleForm)
			this.$myMessage("编辑成功",'success')
			this.handleClose()
			this.$emit('upDataInfo')
		},
		// 获取员工列表
		async _allUserList() {
			const res = await allUserList()
			console.log('员工列表',res)
			this.employeesList = res.data
		},


		submitForm(formName) {
			
			this.$refs[formName].validate(valid => {
				if (valid) {
					console.log(this.ruleForm);

					this._editSchedule()
				} else {
					return false;
				}
			});
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.handleClose();
		},
		// 选择跟进方式
		chooseWay(item, index) {
			// this.wayIndex = index;

			// this.ruleForm.followType = item.settingId;
		},
		handleClose() {
			this.$refs['ruleForm'].resetFields();
			this.drawer = false;
		}
	}
};
</script>

<style scoped lang="scss">
::v-deep {
	.el-form-item__content {
		line-height: 20px;
	}
	.selectbox1 {
		margin: 0;
		.el-input__inner {
			width: 250px !important;
			height: 40px !important;
		}
	}
}

::v-deep {
	.inputbox {
		.el-input__inner {
			width: 600px !important;
			height: 40px !important;
		}
	}

	.el-form-item {
		margin: 0;
	}
}
::v-deep {
	.choosetime {
		.el-input__inner {
			width: 400px !important;
			height: 40px !important;
		}
	}
}

.formbox {
	width: 100%;
	padding: 30px 40px;
	input:focus {
		border: 1px solid #4d91ff;
	}
	input {
		padding: 0 20px;
		width: 100%;
		outline: none;
		border: 1px solid #eeeeee;
		height: 40px;
		font-size: 14px;
		border-radius: 4px;
	}
}
.inputtitle {
	margin: 0 0 15px 0;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #666666;
	span {
		color: #ff0000;
		margin: 0 10px 0 0;
	}
}
.oneway {
	cursor: pointer;
	margin: 0 30px 10px 0;
	display: flex;
	align-items: center;
	div {
		width: 14px;
		height: 14px;
		background: #ffffff;
		border: 1px solid #cccccc;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 10px 0 0;
		&.activeway {
			border: 1px solid #2e77f6;
		}
		p {
			width: 8px;
			background-color: #2e78f6;
			height: 8px;
			border-radius: 50%;
		}
	}
}
.allway {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 0 0 0 17px;
}
::v-deep {
	.el-input__inner {
		width: 520px !important;
		height: 40px !important;
	}
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 70px 0 0 0;
	div:active {
		opacity: 0.6;
	}
	div {
		cursor: pointer;
		width: 180px;
		height: 40px;
		background: #f7f7f7;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		&.save {
			background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
			color: #ffffff;
			margin: 0 0 0 50px;
		}
	}
}
textarea:focus {
	border: 1px solid #4d91ff;
}
textarea {
	font-size: 14px;
	outline: none;
	padding: 13px 20px;
	width: 100%;
	height: 90px;
	background: #ffffff;
	border-radius: 4px;
	border: 1px solid #eeeeee;
	max-width: 100%;
	min-width: 100%;
	min-height: 50px;
}
.titlebox {
	border-bottom: 1px solid #eeeeee;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 52px;
	padding: 0 20px;
	i {
		cursor: pointer;
	}
	span {
		font-size: 1.125rem;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #303030;
	}
}
::v-deep {
	.el-drawer {
		width: 700px !important;
		border-radius: 10px 0px 0px 10px !important;
	}
}
</style>
