<template>
	<div class="gonghai">
		<div class="top1">
			<div></div>
			<span>日程管理</span>
		</div>

		<div class="tablebox">
			<div class="tabletop">
				<div class="inputbox">
					<input placeholder="关键词搜索" v-model="searchInfo.searchBody" />
					<div @click="_scheduleList" class="flex"><i class="el-icon-search" style="color: #AAAAAA;"></i></div>
				</div>
				<div class="selectbox">
					<span>开始日期</span>
					<el-date-picker
						v-model="searchInfo.startTime"
						value-format="yyyy-MM-dd"
						type="date"
						placeholder="选择日期"
					></el-date-picker>
				</div>
				<div class="selectbox">
					<span>结束日期</span>
					<el-date-picker
						v-model="searchInfo.endTime"
						value-format="yyyy-MM-dd"
						type="date"
						placeholder="选择日期"
					></el-date-picker>
				</div>
				<div class="chaxun nochoosetext flex" style="margin-left: 2.5em;" @click="_scheduleList">
					<span>查询</span>	
				</div>
				<div class="chaxun nochoosetext flex" @click="clickReset"><span>重置</span></div>
				<div class="addbox" @click="showAdd" v-if="permissionList.includes('schedule:add') || isAdmin">
					<i class="el-icon-plus" style="color: #2F79FF;"></i>
					<span>新增</span>
				</div>
			</div>

			<div class="allcountbox">
				共筛选出 <span style="color: #3266F7;">{{totalCount}}</span> 条日程记录
			</div>

			<div style="padding: 1.25em;">
				<el-table :data="tableData" border style="width: 100%;">
					<el-table-column label="序号" type="index" width="80"></el-table-column>
					<el-table-column prop="scheduleContent" label="日程内容" width="200"></el-table-column>
					<el-table-column prop="startTime" label="开始时间"></el-table-column>
					<el-table-column prop="endTime" label="截止时间"></el-table-column>
					<el-table-column prop="executeUserNames" label="执行人员"></el-table-column>
					<el-table-column prop="status" label="日程状态">
						<template slot-scope="scope">
							{{ scope.row.status === 1 ? '已完成' : '未完成' }}
						</template>
					</el-table-column>
					<el-table-column prop="endTime" label="完成时间"></el-table-column>

					<el-table-column label="操作" width="250">
						<template slot-scope="scope">
							<div class="twobtn">
								<div class="kanbox" style="margin-left: 1.25em;" @click="getScheduleDetail(scope.row.scheduleId,1)">
									<img src="../../assets/btnicon/image7.png"/>
									看
								</div>

								<div class="kanbox gai" @click="getScheduleDetail(scope.row.scheduleId,2)" v-if="permissionList.includes('schedule:edit') || isAdmin">
									<img src="../../assets/btnicon/image3.png" />
									改
								</div>

								<div class="kanbox shan" @click="showDelete(scope.row)" v-if="isAdmin">
									<img src="../../assets/btnicon/image6.png" />
									删
								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>

				<div class="pagebox">
					<el-pagination
						background
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="searchInfo.page"
						:page-sizes="[5, 10, 15, 20]"
						:page-size="searchInfo.pageSzie"
						layout="total, sizes, prev, pager, next, jumper"
						:total="totalCount"
					></el-pagination>
				</div>
			</div>
		</div>
		<!-- <add-genjin ref="addGenjin"></add-genjin> -->
		<edit-schedule @upDataInfo="_scheduleList" ref="editSchedule"></edit-schedule>
		<add-schedule @upDataInfo="_scheduleList" ref="addSchedule"></add-schedule>
		<delete-popup @deleteInfo="_deleteSchedule" ref="deletePopup" message="确认要删除这条日程信息吗？"></delete-popup>
		<schedule-detail ref="scheduleDetail"></schedule-detail>
	</div>
</template>

<script>
import { scheduleList,deleteSchedule,getScheduleDetail } from '@/network/api.js';

import editSchedule from './popup/editSchedule.vue';
import addSchedule from './popup/addSchedule.vue';
import deletePopup from '../../components/common/deletePopup.vue';
import scheduleDetail from './popup/scheduleDetail.vue';
export default {
	data() {
		return {
			value1: '',
			currentPage4: 4,
			companyList: [
				{
					value: '阿里',
					lable: '阿里'
				}
			],
			company: '',
			searchInfo: {
				endTime: '',
				isAsc: 'asc',
				orderByColumn: '',
				page: 1,
				pageSize: 10,
				searchBody: '',
				startTime: ''
			},
			totalCount: 0,
			tableData: [],
			deleteId: '',   // 要删除的日程id
		};
	},
	components: {
		editSchedule,
		addSchedule,
		deletePopup,
		scheduleDetail
	},
	name: 'scheduleManage',
	created() {
		this._scheduleList();
	},
	computed: {
		permissionList() {
			return this.$store.state.permissionList 
		},
		isAdmin() {
			return (this.$store.state.isadmin && localStorage.getItem('isStaff') == '0')
		},
	},
	methods: {
		// 获取日程详情
		async getScheduleDetail(id,type) {
			const res = await getScheduleDetail({scheduleId:id})
			console.log("日程详情",res);
			let userid = res.data.userInfo.map(item => item.userId) || []
			this.$refs.editSchedule.ruleForm = {
				endTime: res.data.endTime,
				executeUserId: userid || [],
				followType: res.data.followType,
				scheduleContent: res.data.scheduleContent,
				scheduleId: res.data.scheduleId,
				scheduleTitle: res.data.scheduleTitle,
				startTime: res.data.startTime,
				status: res.data.status
			}
			this.$refs.scheduleDetail.ruleForm = {
				endTime: res.data.endTime,
				executeUserId: userid || [],
				followType: res.data.followType,
				scheduleContent: res.data.scheduleContent,
				scheduleId: res.data.scheduleId,
				scheduleTitle: res.data.scheduleTitle,
				startTime: res.data.startTime,
				status: res.data.status
			}
			if (type === 1) {
				this.$refs.scheduleDetail.drawer = true;
			} else if (type === 2) {
				this.$refs.editSchedule.drawer = true;
			}
		},
		// 删除日程
		async _deleteSchedule() {
			const res = await deleteSchedule({scheduleId: this.deleteId})
			this.$refs.deletePopup.dialogVisible = false;
			this.$myMessage('删除成功','success')
			this._scheduleList()
		},
		// 点击重置
		clickReset() {
			this.searchInfo.endTime = '';
			this.searchInfo.searchBody = '';
			this.searchInfo.startTime = '';
		},
		// 获取日程列表
		async _scheduleList() {
			// return
			const res = await scheduleList(this.searchInfo);
			console.log('日程列表', res);
			this.tableData = res.data.list;
			this.totalCount = res.data.totalCount;
		},

		// 点击出现删除弹框
		showDelete(info) {
			this.deleteId = info.scheduleId
			this.$refs.deletePopup.dialogVisible = true;
		},

		// 点击出现添加弹框
		showAdd() {
			this.$refs.addSchedule.drawer = true;
		},
		handleSizeChange(val) {
			this.searchInfo.pageSize = val;
			this._scheduleList();
		},
		handleCurrentChange(val) {
			this.searchInfo.page = val;
			this._scheduleList();
		}
	}
};
</script>

<style scoped lang="scss">
	.allcountbox {
		width: 100%;
		background-color: #EFF3F6;
		height: 3.25em;
		padding: 0 1.25em;
		font-size: 1em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #5B6271;
		display: flex;
		align-items: center;
		span {
			margin: 0 0.3em;
		}
	}
.top1 {
	width: 100%;
	height: 3.25em;
	background-color: #ffffff;
	display: flex;
	align-items: center;
	padding: 0 1.0625em;
	div {
		width: 0.1875em;
		height: 1.125em;
		background: linear-gradient(270deg, #0463ec 0%, #3286ff 100%);
		border-radius: 0.125em;
	}
	span {
		font-size: 1.125em;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		margin: 0 0 0 0.625em;
		color: #303030;
	}
}
::v-deep {
	.pagebox {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		margin: 6.25em 0 0 1.25em;
		padding: 0 1.25em 0 0;
		.el-input__inner {
			color: #666666;
			width: 3em;
			height: 2.25em;
			line-height: 2.25em;
		}
		.el-select {
			.el-input__inner {
				color: #666666;
				width: 8em;
				height: 2.25em;
				line-height: 2.25em;
			}
		}

		.el-input__icon {
			line-height: 2.25em;
		}
	}
}
.kanbox:active {
	opacity: 0.6;
}
.kanbox {
	cursor: pointer;
	width: 3.625em;
	height: 1.625em;
	background: #0091ff;
	border-radius: 0.8125em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 0.675em;
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #ffffff;
	img {
		width: 0.9375em;
		height: 1.0625em;
	}
	&.shan {
		background-color: #b3bcc9;
		margin: 0 0 0 1.25em;
		img {
			width: 0.9375em;
			height: 1em;
		}
	}
	&.gen {
		background-color: #fa7e33;
		margin: 0 0 0 1.25em;
		img {
			width: 0.8125em;
			height: 1.0625em;
		}
	}
	&.gai {
		background-color: #3dc57c;
		margin: 0 0 0 1.25em;
		img {
			width: 0.875em;
			height: 0.875em;
		}
	}
	&.zeng {
		background-color: #9f7eff;
		margin: 0 0 0 1.25em;
		img {
			width: 0.875em;
			height: 0.8125em;
		}
	}
	img {
		width: 1em;
		height: 0.9375em;
	}
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: center;
}
::v-deep {
	.cell {
		text-align: center;
	}
	tr {
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 400;
		color: #868e99;
	}
	th {
		background-color: #f6f7fb !important;
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #5b6271;
	}
}
thead {
}
.addbox:active {
	opacity: 0.6;
}
.addbox {
	border: 1px solid #2F79FF;
	margin: 0 0 0 1.25em;
	cursor: pointer;
	width: 6.75em;
	height: 2.25em;
	background-color: rgb(234,242,254);
	border-radius: 0.25em;
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		font-size: 0.875em;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #2F79FF;
		margin: 0 0 0 1.25em;
	}
}
::v-deep {
	.el-button {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.chaxun:active {
	background-color: rgb(234,242,255);
	color: #2F79FF;
	border: 1px solid #2F79FF;
}
.chaxun {
	cursor: pointer;
	margin: 0 0 0 1.25em;
	width: 5.625em;
	height: 2.25em;
	border-radius: 0.25em;
	border: 1px solid #DDDDDD;
	font-weight: 400;
	color: #333333;
	font-family: PingFang-SC-Medium, PingFang-SC;
	span {
		font-size: 0.875em;
	}
}
.tabletop {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 1em 1.25em 1.25em;
}
::v-deep {
	.el-input__inner {
		color: #666666;
		width: 10.5em;
		height: 2.25em;
		line-height: 2.25em;
	}
	.el-input__icon {
		line-height: 2.25em;
	}
}
::v-deep {
	.selectbox {
		margin: 0 0 0 2.5em;
		display: flex;
		align-items: center;
		// overflow: hidden;
		span {
			font-size: 0.875em;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #333333;
			margin: 0 0.6875em 0 0;
		}
		.el-date-editor{
			height: 2.25em !important;
			padding: 0;
		}
		.el-input__inner {
			color: #666666;
			width: 9.75em;
			height: 2.25em;
			line-height: 2.25em;
			padding-right: 10px;
		}
	}
}

.tablebox {
	margin: 0.9375em 0 0 0;
	background-color: #ffffff;
	
}
.inputbox {
	display: flex;
	align-items: center;
	background: #ffffff;
	border-radius: 0.25em;
	border: 1px solid #e4e4e4;
	height: 2.25em;
	width: 12.5em;
	justify-content: space-between;
	input {
		outline: none;
		border: 0;
		width: 10.25em;
		font-size: 0.875em;
		padding: 0 0.375em 0 0.875em;
	}
	div:active {
		opacity: 0.6;
	}
	div {
		cursor: pointer;
		width: 2.25em;
		height: 2.25em;
		background: #faf9fb;
		border-radius: 0px 0.25em 0.25em 0px;
		border: 1px solid #e4e4e4;
	}
}

.gonghai {
	padding: 0.9375em;
}
@media screen and (max-width: 1920px) {
	.gonghai {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.gonghai {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.gonghai {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.gonghai {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.gonghai {
		font-size: 12px;
	}
}
</style>
